.bigCard {
    background-color: #f5f5f5 !important;
    border-radius: 5px !important;
}

.smallCard {
    background-color: #f5f5f5 !important;
    border-radius: 3px !important;
}

.ant-card-head {
    font-weight: bold !important;
    border-bottom: 0px !important;
    background-color: #f5f5f5 !important;
    font-size: 16px !important;
}

.cajapadre{
 display: flex;
 align-items: baseline;
}
.cajapadre .titulo{
    font-size: 16px ;
    padding-right: 0.5em;
}
.cajapadre .subtitulo{
    font-size: 10px ;
    font-style:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-weight: 500;
}

.send-and-check .ant-form-item-control-input-content{
    display:flex;
}